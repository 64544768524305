import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ModalController } from '@ionic/angular';
import { IonicModule } from '@ionic/angular';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { CommonModule } from '@angular/common';//ngClass,ngStyle
import { FormsModule } from '@angular/forms';//ngModel

@Component({
  selector: 'app-pdf-modal',
  templateUrl: './pdf-modal.page.html',
  styleUrls: ['./pdf-modal.page.scss'],
  standalone: true,
  imports: [IonicModule, CommonModule, FormsModule, PdfViewerModule]
})
export class PdfModalPage implements OnInit {
  @Input() data: any;
  isOriginal: boolean = false;
  constructor(private modalCtrl: ModalController) { }

  closeModal() {
    this.modalCtrl.dismiss();
  }
  handleDoubleClick() {
    this.isOriginal = !this.isOriginal;
  }

  ngOnInit() { }
}
